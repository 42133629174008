<template>
  <v-card flat>
    <div class="mt-4">
      <v-row class="mx-4 mt-9" no-gutters>
        <v-col cols="12" md="12" sm="12" lg="12" xl="12">
          <v-row no-gutters>
            <v-col cols="12" md="4" sm="12" lg="4" xl="4">
              <v-card
                style="border-style: solid; border-color: #b0b0b0"
                class="elevation-0 pa-2 mb-2 mr-2"
              >
                <v-btn
                  height="45px"
                  width="45px"
                  absolute
                  top
                  left
                  class="mt-n10 gradient-bg white--text"
                  @click="$router.push('Transactions')"
                >
                  <v-icon>mdi-cash-register</v-icon>
                </v-btn>
                <v-card-text class="ml-n4" align="right">
                  <div style="font-size: 20px">
                    <v-btn
                      small
                      height="25px"
                      @click="paymentdialog = true"
                      class="gradient-bg mr-2 mt-n3 white--text"
                      ><strong>{{ value }} </strong></v-btn
                    >

                    <strong class="mr-2">Credits</strong>
                    <v-tooltip bottom color="primary">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          x-small
                          icon
                          color="black"
                          class="gradient-bg white--text mr-n2 mt-n1"
                        >
                          <v-icon color="" small
                            >mdi-information-outline</v-icon
                          >
                        </v-btn>
                      </template>
                      <div
                        class="textWrap white--text"
                        style="max-width: 300px"
                      >
                        1 scan = 1 Credit, 1 form submission = 1 Credit, Live
                        Tracking 5 pins = 1 Credit
                      </div>
                    </v-tooltip>
                    <!-- <v-menu open-on-hover bottom offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="gradient-bg white--text mr-n2 mt-n1"
                          x-small
                          icon
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon color="" small
                            >mdi-information-outline</v-icon
                          >
                        </v-btn>
                      </template>
                      <v-card class="elevation-5" width="400px">
                        <v-toolbar
                          align-center
                          height="35px"
                          dense
                          class="gradient-bg"
                        >
                          <v-card-text
                            style="font-size: 15px"
                            align="center"
                            class="white--text"
                            >Credits Tariff</v-card-text
                          >
                        </v-toolbar>
                        <v-card-text>
                          <v-row class="mt-n4">
                            <v-col
                              style="font-size: 15px"
                              class="font-weight-bold"
                              cols="12"
                              sm="12"
                              md="6"
                              xs="12"
                              lg="6"
                              xl="6"
                              align="center"
                              justify="center"
                            >
                               Source
                            </v-col>
                            <v-col
                              style="font-size: 15px"
                              class="font-weight-bold"
                              cols="12"
                              sm="12"
                              md="6"
                              xs="12"
                              lg="6"
                              xl="6"
                              align="center"
                              justify="center"
                            >
                              Credits Consumed
                            </v-col>
                            <v-col
                              style="font-size: 15px"
                              class="font-weight-bold"
                              cols="12"
                              sm="12"
                              md="6"
                              xs="12"
                              lg="6"
                              xl="6"
                              align="center"
                              justify="center"
                            >
                            Scan
                            </v-col>
                            <v-col
                              style="font-size: 15px"
                              class="font-weight-bold"
                              cols="12"
                              sm="12"
                              md="6"
                              xs="12"
                              lg="6"
                              xl="6"
                              align="center"
                              justify="center"
                            >
                              1
                            </v-col>
                            <v-col
                              style="font-size: 15px"
                              class="font-weight-bold"
                              cols="12"
                              sm="12"
                              md="6"
                              xs="12"
                              lg="6"
                              xl="6"
                              align="center"
                              justify="center"
                            >
                            Form submission
                            </v-col>
                            <v-col
                              style="font-size: 15px"
                              class="font-weight-bold"
                              cols="12"
                              sm="12"
                              md="6"
                              xs="12"
                              lg="6"
                              xl="6"
                              align="center"
                              justify="center"
                            >
                              1
                            </v-col>
                          </v-row>
                          
                        </v-card-text>
                      </v-card>
                    </v-menu> -->
                    <!-- <v-menu open-on-hover bottom offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="gradient-bg white--text mr-n2 mt-n1"
                          x-small
                          icon
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon color="" small
                            >mdi-information-outline</v-icon
                          >
                        </v-btn>
                      </template>
                      <v-card class="elevation-5" width="600px">
                        <v-toolbar
                          align-center
                          height="35px"
                          dense
                          class="gradient-bg"
                        >
                          <v-card-text
                            style="font-size: 15px"
                            align="center"
                            class="white--text"
                            >Credits Tariff</v-card-text
                          >
                        </v-toolbar>
                        <v-card-text>
                          <div class="font-weight-bold">
                            Credit consumption per scan by country For every
                            scan, the credit consumed is different based on the
                            country where the scan was done. 1, 2 or 3 credits
                            are consumer per scan based on the below
                            categorization -
                          </div>
                       
                          <div class="font-weight-bold">
                            Credit Consumed : 3
                          </div>
                          <div class="font-weight-regular">
                            {{ countrycode.three }}
                          </div>
                          <div class="font-weight-bold">
                            Credit Consumed : 2
                          </div>
                          <div class="font-weight-regular">
                            {{ countrycode.two }}
                          </div>
                          <div class="font-weight-bold">
                            Credit Consumed : 1
                          </div>
                          <div class="font-weight-regular">
                            Remaining Countries
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-menu> -->

                    <!-- <v-menu open-on-hover bottom offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="gradient-bg white--text mr-n2 mt-n1"
                          x-small
                          icon
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon color="" small
                            >mdi-information-outline</v-icon
                          >
                        </v-btn>
                      </template>
                      <v-card class="elevation-5" width="400px">
                        <v-toolbar
                          align-center
                          height="35px"
                          dense
                          class="gradient-bg"
                        >
                          <v-card-text
                            style="font-size: 15px"
                            align="center"
                            class="white--text"
                            >Credits Tariff</v-card-text
                          >
                        </v-toolbar>
                        <v-card-text>
                          <div>
                            <v-row class="mt-n4">
                              <v-col
                                style="font-size: 15px"
                                class="font-weight-bold"
                                cols="12"
                                sm="12"
                                md="6"
                                xs="12"
                                lg="6"
                                xl="6"
                                align="center"
                                justify="center"
                              >
                                Scan Source
                              </v-col>
                              <v-col
                                style="font-size: 15px"
                                class="font-weight-bold"
                                cols="12"
                                sm="12"
                                md="6"
                                xs="12"
                                lg="6"
                                xl="6"
                                align="center"
                                justify="center"
                              >
                                Credits Consumed
                              </v-col>
                            </v-row>
                            <v-row
                              class="my-2"
                              v-for="(item, idx) in legends_array"
                              :key="idx"
                              no-gutters
                            >
                              <v-col
                                cols="12"
                                sm="12"
                                md="2"
                                xs="12"
                                lg="2"
                                xl="2"
                                align="left"
                                justify="left"
                              >
                                <v-icon
                                  color="green"
                                  v-if="item.source == 'WHATSAPP'"
                                  small
                                  >mdi-whatsapp</v-icon
                                >

                                <v-icon
                                  color="primary"
                                  v-if="item.source == 'CAMERA'"
                                  small
                                  >mdi-camera</v-icon
                                >
                           
                                <v-icon
                                  color="brown"
                                  v-if="item.source == 'FACE'"
                                  small
                                  >mdi-account-box-outline</v-icon
                                >
                                <v-icon
                                  color="purple"
                                  v-if="item.source == 'DATACORRECTION'"
                                  small
                                  >mdi-human-edit</v-icon
                                >
                                <v-icon
                                  color="blue"
                                  v-if="item.source == 'PROXY'"
                                  small
                                  >mdi-odnoklassniki</v-icon
                                >
                                <v-icon
                                  color="black"
                                  v-if="item.source == 'BEACON'"
                                  small
                                  >mdi-bluetooth-audio</v-icon
                                >
                                <v-icon
                                  color="pink"
                                  v-if="item.source == 'BOX'"
                                  small
                                  >mdi-card-account-details-outline</v-icon
                                >
                                <v-icon
                                  color="black"
                                  v-if="item.source == 'VISITORSCAN'"
                                  small
                                >
                                  mdi-human</v-icon
                                >
                                <v-icon
                                  color="black"
                                  v-if="item.source == 'DESKTOP'"
                                  small
                                >
                                mdi-desktop-classic</v-icon
                                >
                                <v-icon
                                  color="black"
                                  v-if="item.source == 'ESA'"
                                  small
                                >
                                mdi-cash</v-icon
                                >
                              </v-col>
                              <v-col
                                class="font-weight-bold"
                                cols="12"
                                sm="12"
                                md="4"
                                xs="12"
                                lg="4"
                                xl="4"
                                align="left"
                                justify="left"
                              >
                                {{ item.source }}
                              </v-col>
                              <v-col
                                cols="12"
                                sm="12"
                                md="4"
                                xs="12"
                                lg="4"
                                xl="4"
                                align="center"
                                justify="center"
                              >
                                {{ item.credits }}
                              </v-col>
                            </v-row>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-menu> -->
                  </div>
                  <v-btn
                    text
                    small
                    @click="$router.push('Transactions')"
                    class="mr-n5 primary--text"
                    ><strong
                      >Balance: {{ TotalCredits }} / Consumed:
                      {{ Consumedcredits }}</strong
                    >
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="3" lg="3" xl="3" sm="12">
              <v-card
                style="border-style: solid; border-color: #b0b0b0"
                class="elevation-0 pa-2 mb-2 mr-2"
              >
                <v-btn
                  absolute
                  @click="$router.push('Members')"
                  height="45px"
                  width="45px"
                  top
                  left
                  class="mt-n10 gradient-bg white--text"
                  ><v-icon>mdi-account-multiple-outline</v-icon></v-btn
                >
                <v-card-text class="ml-n4" align="right">
                  <div style="font-size: 20px">
                    <strong class="mr-n1">Members</strong>
                  </div>
                  <div>
                    <v-btn
                      small
                      @click="$router.push('Members')"
                      class="mr-n5 primary--text"
                      text
                      ><strong
                        >Active: {{ ActiveMembers }} / InActive:
                        {{ InactiveMembers }}</strong
                      ></v-btn
                    >
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="3" lg="3" xl="3" sm="12">
              <v-card
                style="border-style: solid; border-color: #b0b0b0"
                class="elevation-0 pa-2 mb-2 mr-2"
              >
                <v-btn
                  @click="$router.push('Locations')"
                  height="45px"
                  width="45px"
                  absolute
                  top
                  left
                  class="mt-n10 gradient-bg white--text"
                  ><v-icon>mdi-map-marker-circle</v-icon></v-btn
                >
                <v-card-text class="ml-n4" align="right">
                  <div style="font-size: 20px">
                    <strong class="mr-n1">Locations</strong>
                  </div>
                  <div>
                    <v-btn
                      text
                      small
                      @click="$router.push('Locations')"
                      class="mr-n5 primary--text"
                    >
                      <!-- <strong
                      >{{ Locations }} (Scan Sources: {{ scanSource }})</strong
                    > -->
                      <strong
                        >Active: {{ ActiveLocations }} / InActive:
                        {{ InactiveLocations }}</strong
                      >
                    </v-btn>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="2" sm="12" lg="2" xl="2">
              <v-card
                style="border-style: solid; border-color: #b0b0b0"
                class="elevation-0 pa-2 mb-2 mr-2"
              >
                <v-btn
                  @click="$router.push('ScanLogs')"
                  height="45px"
                  width="45px"
                  absolute
                  top
                  left
                  class="mt-n10 gradient-bg white--text"
                >
                  <v-icon>mdi-data-matrix-scan</v-icon>
                </v-btn>
                <v-card-text align="right">
                  <div style="font-size: 20px"><strong>Scans</strong></div>
                  <v-btn
                    text
                    small
                    @click="$router.push('ScanLogs')"
                    class="primary--text"
                    ><strong class="mr-n4"> Total: {{ TotalScan }}</strong>
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col md="6" sm="12" xs="12" xl="6" lg="6">
          <v-card elevation="0" :loading="isLoading">
            <DashboardMap :new_maps_array="new_maps_array" />
          </v-card>
        </v-col>
        <v-col class="ml-n1" md="6" sm="12" xs="12" xl="6" lg="6">
          <v-card class="mt-4 ml-2 mr-4 elevation-0">
            <v-card-text>
              <v-data-table
                dense
                :headers="scanLogsData"
                :loading="isLoading"
                loading-text="Loading... Please wait"
                :items="swipeList"
                class="bx--front-line overflow mt-n6"
                :items-per-page="10"
                hide-default-footer
              >
                <template v-slot:[`item.swipe_date`]="{ item }">
                  <span style="margin-left: -10px" v-text="get_date(item)">
                  </span>
                </template>
                <template v-slot:[`item.type`]="{ item }">
                  <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on }">
                      <div v-on="on" v-if="item.source == 'LIVE'">
                        <v-icon color="#757575" small
                          >mdi-map-marker-account</v-icon
                        >
                      </div>
                      <div v-on="on" v-if="item.source == 'WHATSAPP'">
                        <v-icon color="#757575" small>mdi-whatsapp</v-icon>
                      </div>
                      <div v-on="on" v-else-if="item.source == 'CAMERA'">
                        <v-icon color="#757575" small>mdi-camera</v-icon>
                      </div>
                      <div v-on="on" v-else-if="item.source == 'RESWIPE'">
                        <v-icon color="#757575" small>mdi-redo-variant</v-icon>
                      </div>
                      <div v-on="on" v-else-if="item.source == 'FACE'">
                        <v-icon color="#757575" small
                          >mdi-face-recognition</v-icon
                        >
                      </div>
                      <div
                        v-on="on"
                        v-else-if="item.source == 'DATACORRECTION'"
                      >
                        <v-icon color="#757575" small>mdi-human-edit</v-icon>
                      </div>
                      <div v-on="on" v-else-if="item.source == 'PROXY'">
                        <v-icon color="#757575" small>mdi-odnoklassniki</v-icon>
                      </div>
                      <div v-on="on" v-else-if="item.source == 'BEACON'">
                        <v-icon color="#757575" small
                          >mdi-bluetooth-audio</v-icon
                        >
                      </div>
                      <div v-on="on" v-else-if="item.source == 'BOX'">
                        <v-icon color="#757575" small
                          >mdi-card-account-details-outline</v-icon
                        >
                      </div>
                      <div v-on="on" v-else-if="item.source == 'DESKTOP'">
                        <v-icon color="#757575" small>
                          mdi-desktop-classic</v-icon
                        >
                      </div>
                      <div v-on="on" v-else-if="item.source == 'RFID'">
                        <v-icon color="#757575" small
                          >mdi-card-account-details-outline</v-icon
                        >
                      </div>
                      <div v-on="on" v-else-if="item.source == 'SIMPLE'">
                        <v-icon color="#757575" small>mdi-line-scan</v-icon>
                      </div>
                    </template>
                    <span v-if="item.source == 'WHATSAPP'" class="white--text"
                      >Source: Whatsapp</span
                    >
                    <span
                      v-else-if="item.source == 'CAMERA'"
                      class="white--text"
                      >Source : QR</span
                    >
                    <span
                      v-else-if="item.source == 'DESKTOP'"
                      class="white--text"
                      >Source : Desktop</span
                    >
                    <span
                      class="white--text"
                      v-else-if="item.source == 'RESWIPE'"
                      >Source : Re-Scan</span
                    >
                    <span
                      v-else-if="item.source == 'DATACORRECTION'"
                      class="white--text"
                    >
                      Source : Data Correction</span
                    >
                    <span
                      v-else-if="item.source == 'PROXY'"
                      class="white--text"
                    >
                      Source : PROXY</span
                    >
                    <span v-else-if="item.source == 'BOX'" class="white--text">
                      Source : BOXs</span
                    >
                    <span
                      v-else-if="item.source == 'BEACON'"
                      class="white--text"
                    >
                      Source : BEACON</span
                    >
                    <span v-else-if="item.source == 'FACE'" class="white--text">
                      Source : FACE</span
                    >
                    <span v-else-if="item.source == 'RFID'" class="white--text">
                      Source : RFID</span
                    >
                    <span
                      v-else-if="item.source == 'SIMPLE'"
                      class="white--text"
                    >
                      Source : Simple</span
                    >
                    <span v-else-if="item.source == 'LIVE'" class="white--text">
                      Source : LIVE</span
                    >
                  </v-tooltip>
                </template>
                <template v-slot:[`item.Phoneno`]="{ item }">
                  {{ getphonenumber(item.Phoneno) }}
                </template>
                <template v-slot:[`no-data`]>
                  <v-alert class="gradient-bg2 white--text"
                    >No swipe data available!</v-alert
                  >
                </template>
                <template v-slot:no-results>
                  <v-alert :value="true" class="gradient-bg2 white--text">
                    No records found!
                  </v-alert>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
          <v-card-actions class="mt-n3">
            <v-spacer></v-spacer>
            <v-btn
              class="mt-n4 mr-2 text-capitalize"
              color="primary"
              text
              @click="$router.push('/ScanLogs')"
              ><u>View More</u> >></v-btn
            >
          </v-card-actions>
        </v-col>
      </v-row>
      <Overlay :overlay="overlay" />
      <RechargeDialog
        :paymentdialog="paymentdialog"
        @closedialog="paymentdialog = false"
      />
    </div>
  </v-card>
</template>

<script>
import { API, graphqlOperation } from "aws-amplify";
import {
  GetSwipesForOrganisations,
  PrznceDashBoard,
  sourceCreditsList,
  country_credits_list_edit,
} from "@/graphql/queries.js";
import DashboardMap from "./DashboardMap.vue";
import Overlay from "@/components/Overlay.vue";
import RechargeDialog from "@/components/Dialogs/RechargeDialog.vue";

export default {
  props: {
    currentSwipedUser: Object,
  },
  components: {
    DashboardMap,
    RechargeDialog,
    Overlay,
  },
  computed: {
    height() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  data: () => ({
    legends_array: [],
    paymentdialog: false,
    scanLogsData: [
      { text: "", value: "type" },
      { text: "Swipe Date", value: "swipe_date" },
      { text: "Member Name", value: "user_name" },
      { text: "Location Name", value: "box_location" },
    ],
    swipeList: [],
    countrycode: {},
    ActiveMembers: "",
    InactiveMembers: "",
    TotalScan: "",
    overlay: false,
    TodayScan: "",
    Locations: "",
    TotalCredits: "",
    Consumedcredits: "",
    scanSource: "",
    new_maps_array: [],
    value: "",
    ActiveLocations: "",
    InactiveLocations: "",
    isLoading: false,
    ScanLogsItems: [],
    countryItems: [],
    timeZone: "",
  }),
  created() {
    this.overlay = true;
    this.isLoading = true;

    // this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.get_swipes();
    this.getDashboardData();
    this.get_rredits_list();
    this.country_credits_list_edit_view();
  },
  watch: {
    TotalCredits(val) {
      this.value = "";
      if (val <= 100) {
        return (this.value = "Recharge Now");
      } else if (val > 100 && val <= 200) {
        return (this.value = "Recharge Soon");
      } else if (val > 201) {
        return (this.value = "Recharge");
      }
    },
    currentSwipedUser(val) {
      this.TotalCredits = val.totalcredits;
      this.Consumedcredits = val.consumedcredits;
      this.currentSwipedUser.timeStamp = val.Time;
      this.currentSwipedUser.Box_Location = val.Location;
      this.currentSwipedUser.type = val.source;
      this.currentSwipedUser.userLat = val.user_lat;
      this.currentSwipedUser.userLong = val.user_long;
      this.swipeList.unshift(this.currentSwipedUser);
      this.new_maps_array.unshift({
        member_name: this.currentSwipedUser.user_name,
        swipe_location: this.currentSwipedUser.box_location,
        position: {
          lat: Number(this.currentSwipedUser.user_lat),
          lng: Number(this.currentSwipedUser.user_long),
        },
      });
      this.$forceUpdate();
    },
  },
  methods: {
    // get_date(val) {
    //   const d = new Date(parseInt(val * 1000));
    //   var date = d.getDate().toLocaleString();
    //   var month = (d.getMonth() + 1).toLocaleString();
    //   var year = d.getFullYear();

    //   return (
    //     (+date <= 9 ? "0" + date : date) +
    //     "/" +
    //     (+month <= 9 ? "0" + month : month) +
    //     "/" +
    //     year
    //   );
    // },
    get_date(date) {
      let a = new Date(Number(date.swipe_time_stamp) * 1000).toLocaleDateString(
        "en-US",
        {
          timeZone:
            date.time_zone == null
              ? Intl.DateTimeFormat().resolvedOptions().timeZone
              : date.time_zone,
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }
      );
      const [month, day, year] = a.split("/");
      // console.log(date.time_zone);
      // Reformatting to "day month year" format
      return `${day}/${month}/${year}`;
    },
    async get_rredits_list() {
      this.overlay = true;
      try {
        await API.graphql(
          graphqlOperation(sourceCreditsList, {
            input: {},
          })
        ).then((res) => {
          this.legends_array = JSON.parse(
            res.data.sourceCreditsList
          ).data.filter(
            (i) =>
              i.source != "RESWIPE" &&
              i.source != "ESA" &&
              i.source != "VISITORSCAN" &&
              i.source != "WHATSAPP"
          );
        });
      } catch (err) {
        console.log("err", err);
        this.overlay = false;
      }
    },
    async get_swipes() {
      this.overlay = true;
      this.isLoading = true;

      try {
        await API.graphql(
          graphqlOperation(GetSwipesForOrganisations, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
              swipe_type_queries: "SWIPES",
            },
          })
        ).then((res) => {
          this.MapsList =
            res.data.GetSwipesForOrganisations.data != null ||
            res.data.GetSwipesForOrganisations.data != undefined
              ? res.data.GetSwipesForOrganisations.data
              : [];
          this.swipeList =
            res.data.GetSwipesForOrganisations.data != null ||
            res.data.GetSwipesForOrganisations.data != undefined
              ? res.data.GetSwipesForOrganisations.data
              : [];
          this.MapsList.splice(10);
          for (let i = 0; i < this.MapsList.length; i++) {
            if (
              this.MapsList[i].user_lat != undefined ||
              this.MapsList[i].user_long != undefined ||
              this.MapsList[i].user_lat != null ||
              this.MapsList[i].user_long != null
            ) {
              this.new_maps_array.push({
                reource_id: this.MapsList[i].user_id,
                member_name: this.MapsList[i].user_name,
                swipe_location: this.MapsList[i].box_location,
                position: {
                  lat: Number(this.MapsList[i].user_lat),
                  lng: Number(this.MapsList[i].user_long),
                },
              });
            }
          }
          this.overlay = false;
          this.isLoading = false;
        });
      } catch (err) {
        console.log("err", err);
        this.overlay = false;
        this.isLoading = false;
      }
    },
    async country_credits_list_edit_view() {
      try {
        let result = await API.graphql(
          graphqlOperation(country_credits_list_edit, {
            input: {
              action_type: "GET",
            },
          })
        );

        this.countryItems = JSON.parse(
          result.data.country_credits_list_edit
        ).data;
        this.countrycode = {
          one: [],
          two: "",
          three: "",
        };

        for (let i = 0; i < this.countryItems.length; i++) {
          if (this.countryItems[i].credits == 1) {
            this.countrycode.one.push(this.countryItems[i].country_name);
          } else if (this.countryItems[i].credits == 2) {
            this.countrycode.two += this.countryItems[i].country_name + ",";
          } else {
            this.countrycode.three += this.countryItems[i].country_name + ",";
          }
        }

        // console.log(this.countrycode.one);
      } catch (err) {
        console.log("error", err);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: err.errors[0].message,
        };
        this.isLoadingMember = false;
        this.overlay = false;
      }
    },
    async getDashboardData() {
      this.overlay = true;
      try {
        await API.graphql(
          graphqlOperation(PrznceDashBoard, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
            },
          })
        ).then((res) => {
          if (res.data.PrznceDashBoard != null) {
            this.scanSource = JSON.parse(
              res.data.PrznceDashBoard
            ).result.total_qr_count;
            this.ActiveMembers = JSON.parse(
              res.data.PrznceDashBoard
            ).result.ActiveUsers;
            this.InactiveMembers = JSON.parse(
              res.data.PrznceDashBoard
            ).result.InActiveUsers;
            this.TotalScan = JSON.parse(
              res.data.PrznceDashBoard
            ).result.total_swipes;
            this.Locations = JSON.parse(
              res.data.PrznceDashBoard
            ).result.total_locations;
            this.TotalCredits = JSON.parse(
              res.data.PrznceDashBoard
            ).result.total_credits;
            this.Consumedcredits = JSON.parse(
              res.data.PrznceDashBoard
            ).result.consumed_credits;
            this.ActiveLocations = JSON.parse(
              res.data.PrznceDashBoard
            ).result.total_active_locations;
            this.InactiveLocations = JSON.parse(
              res.data.PrznceDashBoard
            ).result.total_inactive_locations;
            // this.chartdataPie.datasets[0].data = [
            //   this.TotalCredits,
            //   this.Consumedcredits,
            // ];
            // this.chartdataPie2.datasets[0].data = [
            //   this.ActiveMembers,
            //   this.InactiveMembers,
            // ];
            // this.chartdataPie3.datasets[0].data = [
            //   this.Locations,
            //   this.InactiveLocations,
            //   this.scanSource,
            // ];
          } else {
            this.overlay = false;
          }
        });
        this.overlay = false;
        this.$forceUpdate();
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style></style>
