<template>
  <v-card class="mt-4 ml-3 elevation-2">
    <GmapMap
      :center="
        new_maps_array[0] != undefined
          ? new_maps_array[0].position
          : dummy_center
      "
      :zoom="5"
      map-type-id="terrain"
      style="width: 100%; height: 385px"
    >
      <GmapMarker
        :key="index"
        v-for="(m, index) in new_maps_array"
        :position="m.position"
        :clickable="true"
        :draggable="false"
        :title="m.member_name + ' swipped at: ' + m.swipe_location"
        @click="center = m.position"
      />
    </GmapMap>
    <!-- :icon="{ url: m.marker }"
        :title="m.member_name + ' swipped at: ' + m.swipe_location" -->
  </v-card>
</template>

<script>
export default {
  props: {
    new_maps_array: Array,
  },
  data: () => ({
    date: "",
    dummy_center: {
      lat: 0,
      lng: 0,
    },
  }),
  mounted() {},
  methods: {
    callmethoddate(displaydate) {
      let a = new Date(displaydate * 1000).toLocaleDateString("en-In", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      return a;
    },
    getMethods(rec) {
      this.date = rec.date;
    },
  },
};
</script>

<style>
</style>